
<div class="sidebar" [ngClass]="{ 'expanded': isExpanded }" id="tibo-large-leftpanel-container">
    <div class="content">
        <div class="link">
            <div class="logo">
                <!-- <span> 
                    <img src="././assets/TB LOGO.png" alt="">
                </span>
                <div class="logo_name">Timebound</div> -->
                <span> <img src="././assets/matrix_logo.png" alt=""></span>
                <div class="logo_name">
                    <div>
                        <span style="font-weight: 900;">reliance</span>matrix
                    </div>
                    <div>
                        <span class="poweredBy">Reporting Platform by Timebound</span>
                    </div>
                </div>
            </div>
        </div><br><br>
        <ul>
            <div class="scroll-class">
                <li class="menu-panel-list" id="shepherd-home" *ngIf="resultMenuList.length>1">
                    <a href="#" routerLink="home" routerLinkActive="active" (click)="onLinkClick()"
                        (click)="activefun('home')" [ngClass]="{'active':activeString === 'home'}" pTooltip="Home"
                        tooltipPosition="right" tooltipStyleClass="custom-tooltip" [tooltipDisabled]="isExpanded">
                        <!-- <i class='scale-class menu-panel-icons bx bx-home'></i> -->
                        <i class="fa-solid fa-house scale-class menu-panel-icons"></i>
                        <span class="group_name">Home</span>
                    </a>
                </li>
                <div *ngFor="let list of resultMenuList;index as i">
                    <li class="menu-panel-list" *ngIf="list ==='DataDiscovery'" id="shepherd-DataDiscovery">
                        <a href="#" routerLink="discovery" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('dataDiscovery')" [ngClass]="{'active':activeString === 'dataDiscovery'}"
                            pTooltip="Data Discovery" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <i class="fa-solid fa-magnifying-glass-chart menu-panel-icons"></i>
                            <span class="group_name">Data Discovery</span>
                        </a>
                    </li>
                    
                    <li class="menu-panel-list" *ngIf="list==='Dashboard'" id="shepherd-myDashboards">
                        <a routerLink="dashboard" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('dashboard')" [ngClass]="{'active':activeString === 'dashboard'}"
                            pTooltip="My Dashboard" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <i class="fa-solid fa-chart-column menu-panel-icons"></i>
                            <!-- <i class='scale-class menu-panel-icons bx bx-grid-alt'></i> -->
                            <!-- <i class="fa-solid fa-border-all scale-class menu-panel-icons"></i> -->
                            <span class="group_name ">My Dashboard</span>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='Custom Reports'" id="shepherd-buildaReport">
                        <a routerLink="build-report" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('buildReport')" [ngClass]="{'active':activeString === 'buildReport'}"
                            pTooltip="Build A Report" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-notepad'></i> -->
                            <!-- <i class="fa-solid fa-calendar-days scale-class menu-panel-icons"></i> -->
                            <i class="fa-regular fa-clipboard scale-class menu-panel-icons"></i>
                            <span class="group_name ">Build A Report</span>
                        </a>
                    </li>
                     <li class="menu-panel-list" *ngIf="list==='Adhoc'">
                        <a routerLink="adhoc" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('adhoc')" [ngClass]="{'active':activeString === 'adhoc'}"
                            pTooltip="Adhoc Report" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-notepad'></i> -->
                            <!-- <i class="fa-solid fa-calendar-days scale-class menu-panel-icons"></i> -->
                            <i class="fa fa-table scale-class menu-panel-icons"></i>
                            <span class="group_name ">Adhoc Report</span>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='My Reports'" id="shepherd-myReports">
                        <a routerLink="my-reports" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('report')" [ngClass]="{'active':activeString === 'report'}"
                            pTooltip="My Reports" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-folder'></i> -->
                            <i class="fa-regular fa-folder scale-class menu-panel-icons"></i>
                            <span class="group_name ">My Reports</span>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='My Infograph'">
                        <a routerLink="customer" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('My Infograph')" [ngClass]="{'active':activeString === 'My Infograph'}"
                            pTooltip="My Infograph" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            
                            <i class="bx bx-chart menu-panel-icons"></i>
                            <span class="group_name ">My Infograph</span>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='Scheduled Reports'">
                        <a routerLink="scheduled-reports" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('scheduleReport')"
                            [ngClass]="{'active':activeString === 'scheduleReport'}" pTooltip="Schedule Reports"
                            tooltipPosition="right" tooltipStyleClass="custom-tooltip" [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-file'></i> -->
                            <i class="pi pi-clock menu-panel-icons"></i>
                            <span class="group_name ">Schedule Reports</span>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='Scheduler Logs'">
                        <a routerLink="schedule-history" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('scheduleHistory')"
                            [ngClass]="{'active':activeString === 'scheduleHistory'}" pTooltip="Schedule History"
                            tooltipPosition="right" tooltipStyleClass="custom-tooltip" [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-file-blank'></i> -->
                            <i class="pi pi-list menu-panel-icons"></i>
                            <span class="group_name ">Schedule History</span>
                            <p-badge *ngIf="getTodayFailedCountSideBar > 0" [value]="getTodayFailedCountSideBar"
                                severity="danger" class="badge-class" [ngClass]="{'badge-class-two':isExpanded}">
                            </p-badge>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='Payroll'">
                        <a routerLink="payrollValidation" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('payrollValidation')"
                            [ngClass]="{'active':activeString === 'payrollValidation'}" pTooltip="Payroll Validation"
                            tooltipPosition="right" tooltipStyleClass="custom-tooltip" [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-file-blank'></i> -->
                            <i class="fa-regular fa-calendar-check scale-class menu-panel-icons"></i>
                            <span class="group_name ">Payroll Validation</span>
                            <p-badge *ngIf="getTodayFailedCountSideBar > 0" [value]="getTodayFailedCountSideBar"
                                severity="danger" class="badge-class" [ngClass]="{'badge-class-two':isExpanded}">
                            </p-badge>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='Payroll'">
                        <a routerLink="payroll" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('payroll')" [ngClass]="{'active':activeString === 'payroll'}"
                            pTooltip="Payroll" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-file-blank'></i> -->
                            <i class="fa-solid fa-money-bill-1 scale-class menu-panel-icons"></i>
                            <span class="group_name ">Payroll</span>
                            <!-- <p-badge *ngIf="getTodayFailedCountSideBar > 0" [value]="getTodayFailedCountSideBar"
                                severity="danger" class="badge-class" [ngClass]="{'badge-class-two':isExpanded}">
                            </p-badge> -->
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='My Inbox'">
                        <a routerLink="my-report-inbox" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('reportInbox')" [ngClass]="{'active':activeString === 'reportInbox'}"
                            pTooltip="Report Inbox" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-data'></i> -->
                            <i class="pi pi-inbox menu-panel-icons"></i>
                            <!-- <i class="fa-solid fa-inbox scale-class menu-panel-icons"></i> -->
                            <span class="group_name ">Report Inbox</span>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='My Favorites'">
                        <a routerLink="my-favorites" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('favorites')" [ngClass]="{'active':activeString === 'favorites'}"
                            pTooltip="Favorites" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-star'></i> -->
                            <i class="fa-regular fa-star scale-class menu-panel-icons"></i>
                            <span class="group_name ">Favorites</span>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='Configuration'">
                        <a routerLink="Config" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('Config')" [ngClass]="{'active':activeString === 'Config'}"
                            pTooltip="Configuration" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-chart'></i> -->
                            <!-- <i class="fa-regular fa-chart-bar scale-class menu-panel-icons"></i> -->
                            <i class="fa-solid fa-gear scale-class menu-panel-icons"></i>
                            <span class="group_name ">Configuration</span>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='Permission'">
                        <a routerLink="Permission" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('Permission')" [ngClass]="{'active':activeString === 'Permission'}"
                            pTooltip="Permission" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-chart'></i> -->
                            <!-- <i class="fa-regular fa-chart-bar scale-class menu-panel-icons"></i> -->
                            <i class="fa-solid fa-key scale-class menu-panel-icons"></i>
                            <span class="group_name ">Permission</span>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list ==='Manage Security'">
                        <a href="#" routerLink="admin-security" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('Manage Security')"
                            [ngClass]="{'active':activeString === 'Manage Security'}" pTooltip="Manage Security"
                            tooltipPosition="right" tooltipStyleClass="custom-tooltip" [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-search'></i> -->
                            <i class="fa-solid fa-lock scale-class menu-panel-icons"></i>
                            <span class="group_name">Manage Security</span>
                        </a>
                    </li>
                    <!-- <li class="menu-panel-list" *ngIf="list==='Data Analytic Dashboard'">
                        <a routerLink="data-analytics" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('Data Analytic Dashboard')"
                            [ngClass]="{'active':activeString === 'Data Analytic Dashboard'}" pTooltip="Data Analytics"
                            tooltipPosition="right" tooltipStyleClass="custom-tooltip" [tooltipDisabled]="isExpanded">
                            <i class="fa-solid fa-chart-line scale-class menu-panel-icons"></i>
                            <span class="group_name ">Data Analytics</span>
                        </a>
                    </li> -->
                    <li class="menu-panel-list" *ngIf="list==='Widget Designer'">
                        <a routerLink="dashboard-designer" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('widget-designer')"
                            [ngClass]="{'active':activeString === 'dashboard-designer'}" pTooltip="Dashboard Designer"
                            tooltipPosition="right" tooltipStyleClass="custom-tooltip" [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-chart'></i> -->
                            <!-- <i class="fa-regular fa-chart-bar scale-class menu-panel-icons"></i> -->
                            <i class="fa-solid fa-palette scale-class menu-panel-icons"></i>
                            <span class="group_name ">Dashboard Designer</span>
                        </a>
                    </li>
                    <li class="menu-panel-list" *ngIf="list==='Report Catalogue'">
                        <a routerLink="report-catalog" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('report-catalog')"
                            [ngClass]="{'active':activeString === 'report-catalog'}" pTooltip="Report Catalog"
                            tooltipPosition="right" tooltipStyleClass="custom-tooltip" [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-chart'></i> -->
                            <!-- <i class="fa-regular fa-chart-bar scale-class menu-panel-icons"></i> -->
                            <i class="fa-solid fa-book scale-class menu-panel-icons"></i>
                            <span class="group_name ">Report Catalog</span>
                        </a>
                    </li>
                    <!-- <li class="menu-panel-list" *ngIf="list==='Self BI'">
                        <a routerLink="self-service" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('self-service')" [ngClass]="{'active':activeString === 'self-service'}"
                            pTooltip="Self Service" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <i class="fa-solid fa-chart-area scale-class menu-panel-icons"></i>
                            <span class="group_name ">Self Service</span>
                        </a>
                    </li> -->
                    
                    <li class="menu-panel-list" *ngIf="list==='Settings'">
                        <a routerLink="settings" routerLinkActive="active" (click)="onLinkClick()"
                            (click)="activefun('settings')" [ngClass]="{'active':activeString === 'settings'}"
                            pTooltip="Settings" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                            [tooltipDisabled]="isExpanded">
                            <!-- <i class='scale-class menu-panel-icons bx bx-cog'></i> -->
                            <i class="fa-solid fa-wrench scale-class menu-panel-icons"></i>
                            <span class="group_name ">Settings</span>
                        </a>
                    </li>
                    
                </div>
                <!-- <li class="menu-panel-list" *ngIf="resultMenuList.length>1">
                    <a routerLink="GuidedTour" routerLinkActive="active" (click)="onLinkClick()" 
                    (click)="activefun('GuidedTour')" [ngClass]="{'active':activeString === 'GuidedTour'}"
                    pTooltip="Guided Tour" tooltipPosition="right"
                        tooltipStyleClass="custom-tooltip" [tooltipDisabled]="isExpanded">
                        <i class="fa-solid fa-compass scale-class menu-panel-icons"></i>
                        <span class="group_name ">Guided Tours</span>
                    </a>
                </li> -->
                <!-- <li class="menu-panel-list" id="shepherd-DataDiscovery">
                    <a href="#" routerLink="discovery1" routerLinkActive="active" (click)="onLinkClick()"
                        (click)="activefun('dataDiscovery1')" [ngClass]="{'active':activeString === 'dataDiscovery1'}"
                        pTooltip="Data Discovery" tooltipPosition="right" tooltipStyleClass="custom-tooltip"
                        [tooltipDisabled]="isExpanded">
                        <i class="fa-solid fa-magnifying-glass-chart menu-panel-icons"></i>
                        <span class="group_name">Data Discovery V2</span>
                    </a>
                </li> -->
                <!-- <li class="menu-panel-list">
                    <a routerLink="help" routerLinkActive="active" (click)="onLinkClick()" 
                    (click)="activefun('help')" [ngClass]="{'active':activeString === 'help'}"
                    pTooltip="Help" tooltipPosition="right"
                        tooltipStyleClass="custom-tooltip" [tooltipDisabled]="isExpanded">
                        <i class="fa-regular fa-circle-question scale-class menu-panel-icons"></i>
                        <span class="group_name ">Help</span>
                    </a>
                </li> -->
            </div>
        </ul>
        <div class="expand-class">
            <div class="menu-button">
                <ul class="nav-list">
                    <li class="menu-panel-list menu_btn">
                        <i class="fa-solid fa-angles-left scale-class menu-panel-icons menu-btn" (click)="menuFunc()"
                            id="menu-btn"></i>
                        <!-- <i class='scale-class menu-panel-icons bx bx-chevrons-left menu-btn' (click)="menuFunc()"
                            id="menu-btn"></i> -->
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>